#navbardash {
    position: relative;
    z-index: 999;

    // -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    // -moz-animation: fadein 2s; /* Firefox < 16 */
    // -ms-animation: fadein 2s; /* Internet Explorer */
    // -o-animation: fadein 2s; /* Opera < 12.1 */
    // animation: fadein 2s;
    // animation-fill-mode: forwards;

    .container-fluid {
        padding: 0 !important;
    }

    .navlogo {
        width: 140px;
        height: auto;
    }

    .bg-custom {
        padding: 30px 0 10px 0;
        // background: linear-gradient(180deg, rgba($color: #8D68FF, $alpha: .1) 0%, rgba($color: #8D68FF, $alpha: 0) 60%);
        transition: 0.3s;
        -webkit-transition: 0.3s !important;
        border-bottom: 1px transparent;
        backdrop-filter: blur(8px) opacity(1);

        // border-bottom: .5px solid #544C6B;
    }

    .navbarWidth {
        width: 90%;
        margin: 0 auto;
        max-width: 1250px;
    }

    .navbarScroll {
        padding: 10px 0;

        background-color: rgba(15, 12, 35, .3);
        backdrop-filter: blur(8px) opacity(1);
        -webkit-backdrop-filter: blur(8px) opacity(1);
        border-bottom: .5px solid #544C6B;
    }

    .nav-link {
        font-family: "DM Sans", sans-serif !important;
        color: #D4CCE8 !important;
        font-size: 14px;
        font-weight: 700 !important;
        border-radius: 15px;
        padding: 8px 16px;
        transition: all .2s ease 0s;
        -webkit-transition: all .2s ease 0s;
    }

    .nav-link:hover {
        color: white !important;
        background-color: rgba($color: #DBC8FF, $alpha: .16);
    }

    .dashboardButton {
        transition: 0.2s !important;
        -webkit-transition: 0.2s !important;
        z-index: 1;
        color: white;
        background: #1D1C30;
        border-radius: 4px;

        display: flex;
        flex-direction: row;
    }

    .navprofile {
        width: 49px;
        height: 49px;
        border-radius: 50%;

    }

    .logoutWrapper {
        width: 49px;
        height: 49px;

        display: flex;
        padding: 17px;
    }

    .nav-logout {
        margin: 0 auto;
    }

    .dashboardButton:hover {
        transform: scale(1.05);
    }

    .active {
        color: #8D68FF;
    }

    .active {
        font-family: "DM Sans", sans-serif !important;
        color: #D4CCE8 !important;
        font-size: 14px;
        font-weight: 700 !important;
        border-radius: 15px;
        transition: all .2s ease 0s;
        -webkit-transition: all .2s ease 0s;

        color: white !important;
        background-color: rgba($color: #DBC8FF, $alpha: .16);
    }
}

@media (max-width: 768px) {
    #navbardash {
        .navbarWidth {
            width: 90%;
            margin: 0 auto;
            max-width: 1400px;
        }

        .bg-custom {
            padding: 20px 0;

            background-color: rgba(15, 12, 35, .3);
            backdrop-filter: blur(8px) opacity(1);
            -webkit-backdrop-filter: blur(8px) opacity(1);
            border-bottom: .5px solid #544C6B;
        }

        .navlogo {
            width: 100px;
            height: auto;
            margin-right: 20px;
        }

        .nav-link {
            padding-left: 0;
        }

        .nav-link:hover {
            background-color: transparent;
        }

        .current {
            padding-left: 18px;
        }

        .scrollspy {
            margin-top: 15px !important;
        }
    }
}

/* Key Frames */

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

// Other

@media all and (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: none;
    }

    .navbar .nav-item:hover .dropdown-menu {
        display: table-cell;
    }

    .navbar .nav-item .dropdown-menu {
        margin-top: 0;
    }
}