.loader:empty {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    width: 22.5px;
    height: auto;
    animation: load8 1.4s infinite linear;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}