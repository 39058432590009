#about {
    background-color: #14102A;

    .aboutGrid {
        width: 80%;
        max-width: 1400px;
        margin: 0 auto;
        padding: 80px 0;

        display: grid;
        grid-template-columns: 1.1fr .9fr;
    }

    h1 {
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 700 !important;
        font-size: 32px;
        margin: 0;
    }

    p {
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 600;
        font-size: 14px;
        margin: 10px 0 20px 0;
    }

    .pricingButton {
        font-family: "DM Sans", sans-serif !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        display: inline-block;
        transition: 0.2s !important;
        -webkit-transition: 0.2s !important;
        z-index: 1;
        padding: 9px 20px !important;
        color: #DBC8FF;
        background: transparent;
        border: 1px solid #DBC8FF;
        border-radius: 15px;
    }

    .pricingButton:hover {
        color: #14102A;
        background: #DBC8FF;
        transform: scale(1.05);
    }

    .teamContainer {
        text-align: right;
        width: 70%;
        margin: 0 auto;
        position: relative;
    }

    .teamImage {
        width: 100%;
        height: auto;
    }

    .teamImage2 {
        width: 100%;
        height: auto;
        position: absolute;
        right: 0;
        z-index: 2;
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media (max-width: 768px) {
        .aboutGrid {
            width: 90%;
            max-width: 1400px;
            margin: 0 auto;
            padding: 40px 0;
            text-align: center;

            display: grid;
            grid-template-columns: 1fr;
        }

        .teamImage {
            width: 100%;
            height: auto;
        }

        .teamImage2 {
            width: 100%;
            height: auto;
            position: absolute;
            right: 0;
            z-index: 2;
        }

        .teamContainer {
            text-align: center;
            width: 100%;
            margin: 35px auto 0 auto;
            position: relative;
            max-width: 400px;
        }

        h1 {
            font-family: "DM Sans", sans-serif;
            color: white;
            font-weight: 700 !important;
            font-size: 24px;
            margin: 0;
            text-align: center;
        }
    }
}