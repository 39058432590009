#IspDash {
  margin: 0 auto;
  padding-top: 110px;
  width: 90%;
  min-height: 100vh;
  max-width: 1250px;

  ::-webkit-scrollbar {
    width: 7px;
    border: 7px solid #39394F;
    transform: matrix(0, 1, -1, 0, 0, 0);
    border-radius: 3px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
  }

  ::-webkit-scrollbar-thumb {
    border: 7px solid #676789;
    border-radius: 3px;
  }

  a {
    text-decoration: none;
  }

  h1 {
    font-size: 16px;
    line-height: 20px;

    color: white;
    font-weight: 600 !important;
    margin-bottom: 5px;
  }

  h2 {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
  }

  .disclaimer2 {
    margin: 10px 0 5px 0;
    font-weight: 400;
    font-size: 14px;
  }

  .disclaimer3 {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: #5A597A;
  }


  .ISPContainer {
    display: grid;
    margin: 0;
    padding: 0;
    gap: 20px 20px;
    grid-auto-flow: row;

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "current-plans disclaimer disclaimer"
      "current-plans proxy-list other-isp-plans"
      "current-plans proxy-list other-isp-plans"
      "current-plans proxy-list other-isp-plans";
  }

  .proxy-list {
    grid-area: proxy-list;
  }

  .other-isp-plans {
    grid-area: other-isp-plans;
  }

  .current-plans,
  .other-isp-plans,
  .disclaimer,
  .proxy-list {
    background: linear-gradient(226.68deg, rgba(52, 55, 71, 0.25) 14.19%, rgba(52, 55, 71, 0) 162.8%);
    border-radius: 6px;
  }

  .current-plans {
    width: 100%;
    justify-content: space-between;
    padding: 25px;
    grid-area: current-plans;

    // min-width: 300px;
  }

  .other-isp-plans {
    width: 100%;
  }

  .disclaimer {
    width: 100%;
    grid-area: disclaimer;
    padding: 25px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .proxy-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .proxy-description {
    display: flex;
    width: 90%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'Circular Std';
    padding: 20px 0;
    margin-top: 5px;

  }

  .proxy-amount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .vert-line {
    border-left: 1.2px solid #414156;
    height: 20px;
  }

  .proxy-num {
    font-size: 13px;
    font-weight: 450;
    color: #5A597A;
    margin-bottom: 0;
  }

  .copyAll {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .copyAll h1 {
    color: #68FFF6;
    font-size: 13px
  }

  .copyIcon {
    width: 13px;
  }

  .copyList {
    margin: 0;
  }

  textarea {
    background-color: transparent;
    overflow-y: scroll;

    font-size: 12px;
    color: white;
    font-weight: 600;

    border: none;
    outline: none;

    transition: 0.3s;

    height: 80%;
    width: 90%;
    // min-width: 290px;
    min-height: 130px;

    -webkit-appearance: none;
    appearance: none;
    resize: none;

  }

  .other-isp-plans {
    width: 100%;
    justify-content: space-between;
    padding: 25px;
    grid-area: other-isp-plans;

  }

  .dashboardButton {
    margin-top: 10px;
    font-family: "DM Sans", sans-serif !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    transition: 0.2s !important;
    -webkit-transition: 0.2s !important;
    display: flex;
    align-items: center;
    color: white;
    background: linear-gradient(302.04deg, #9870FF 2.07%, #AC87FF 73.72%);
    border-radius: 7px;
    width: 100%;
    justify-content: space-around;
    padding: 15px 0;
  }

  .dashboardButton:hover {
    color: white;
    transform: scale(1.05);
    text-decoration: none;
  }




  /* @media (max-width: 1100px) {
    .ISPContainer {
      display: grid;
      margin: 0;
      padding: 0;
      gap: 20px 20px;
      grid-auto-flow: row;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        "disclaimer disclaimer disclaimer"
        "current-plans proxy-list other-isp-plans"
        "current-plans proxy-list other-isp-plans";
    }

    .disclaimer {
      width: 100%;
      height: 50%;
      grid-area: disclaimer;
      padding: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .proxy-list {
      grid-area: proxy-list;
    }

    .other-isp-plans {
      grid-area: other-isp-plans;
    }

    .current-plans {
      grid-area: current-plans;
    }
  } */

  @media (max-width: 1100px) {
    .ISPContainer {
      display: block;
      margin: 0;
      padding: 0;
    }

    .current-plans {
      margin: 15px 0;
    }

    .other-isp-plans {
      margin-top: 15px;
    }

    .proxy-list {
      min-height: 215px;
    }
  }

}