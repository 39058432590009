#releases {
    display: flex;
    align-items: center;
    margin-top: 10px;
    // gap: 15px;

    .releaseDetails {
        margin-left: 12px;
    }

    .shoeImage {
        width: auto;
        height: 45px;
    }

    h1 {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        margin: 0 !important;
    }

    h2 {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 450;
        font-size: 12px;
        line-height: 15px;

        color: #D4CCE8;
        margin: 0 !important;


    }

    p {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 450;
        font-size: 12px;
        line-height: 15px;
        margin: 1px 0 0 0 !important;

        color: #5A597A;
    }
}

a {
    text-decoration: none !important;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
}