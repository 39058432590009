#HomeDash {
  margin: 0 auto;
  padding-top: 110px;
  width: 90%;
  min-height: 100vh;
  max-width: 1250px;

  h1 {
    font-weight: 600 !important;
    font-size: 16px;

    color: #FFFFFF;
  }

  p {
    font-family: 'Circular Std';
    font-weight: 400;
    font-size: 14px;

    color: rgba(212, 204, 232, 0.45);
  }

  textarea {
    overflow: hidden !important;
  }

  .TopRow {
    display: grid;
    grid-template-columns: 1.2fr .8fr;
    gap: 25px;
  }


  .stayConnected,
  .upcomingReleases {
    background: linear-gradient(226.68deg, rgba(52, 55, 71, 0.25) 14.19%, rgba(52, 55, 71, 0) 162.8%);
    border-radius: 6px;
  }

  .stayConnected,
  .imageDisplay {
    height: auto;
  }

  .slideshowImg {
    width: 100%;
    height: 100%;
    max-height: 400px;

    object-fit: cover;

    border-radius: 6px;
  }

  .stayConnected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 55px;

    padding: 30px 0;
  }

  .connectedContent {
    width: 85%;
    margin: 0 auto;
  }

  .socialBtns {
    display: inline-flex;
    width: 100%;
    gap: 10px;
  }

  .discordBtn {
    background: #262639;
    border-radius: 4px;
    justify-content: space-around;
    display: flex;
    flex: auto;
    align-items: center;
    padding: 25px 0;
    transition: 0.2s !important;
    -webkit-transition: 0.2s !important;
    z-index: 1;

  }

  .twitterBtn {
    background: #262639;
    border-radius: 4px;
    justify-content: space-around;
    display: flex;
    flex: auto;
    align-items: center;
    transition: 0.2s !important;
    -webkit-transition: 0.2s !important;
    z-index: 1;

  }

  .discordBtn:hover {
    background: #5865F2;
    transform: scale(1.05);
  }

  .twitterBtn:hover {
    background: #1DA1F2;
    transform: scale(1.05);
  }

  .mailListText {
    margin-top: 55px;
  }

  .mailListContent {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
  }

  .emailTextbox {
    flex: auto;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    resize: none;
    background: rgba(52, 55, 71, 0.36);
    border-radius: 4px;
    border: none;
    font-style: normal;
    font-weight: 450;
    font-size: 15px;
    color: rgba(212, 204, 232, 0.45);
    max-height: 52px;
    padding: 15px 20px !important;

    outline: none;
    transition: .1s;
    border: transparent 1px solid;
  }

  .emailTextbox:focus {
    border: 1px solid white;
  }

  .joinBtn {
    font-family: "DM Sans", sans-serif !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    display: inline-block;
    transition: 0.2s !important;
    -webkit-transition: 0.2s !important;
    z-index: 1;
    padding: 15px 18px !important;
    color: white;
    background: linear-gradient(302.04deg, #9870FF 2.07%, #AC87FF 73.72%);
    border-radius: 4px;
    text-decoration: none;
  }

  .joinBtn:hover {
    color: white;
    transform: scale(1.05);
  }

  .releasesContent {
    width: 90%;
    margin: 25px auto 0 auto;
    padding: 40px 0;
  }

  .releaseComponents {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: space-between;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .dot {
    background: #8D68FF !important;
    box-shadow: 1px 1px 2px rgb(0 0 0 / 20%) !important;

    height: 10px !important;
    width: 10px !important;
  }

  .dot .selected {
    opacity: 1 !important;
  }

  @media (max-width: 1020px) {
    .TopRow {
      display: grid;
      grid-template-columns: 1.3fr .7fr;
      gap: 25px;
    }

  }

  @media (max-width: 960px) {
    .releaseComponents {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      justify-content: space-between;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }


    .TopRow {
      display: grid;
      grid-template-columns: 1fr;
      gap: 25px;
    }

    .mailListText {
      margin-top: 40px;
    }
  }

  @media (max-width: 768px) {
    .releaseComponents {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      justify-content: space-between;
      grid-row-gap: 10px;
      grid-column-gap: 0;
    }
  }

}