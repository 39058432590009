#features {
    overflow-x: hidden;

    .featureStyling {
        width: 80%;
        max-width: 1400px;
        margin: 0 auto;
        padding: 125px 0 0 0;
        text-align: center;
    }

    .featureGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 50px;
        margin: 70px auto 30px auto;
    }

    h1 {
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 700 !important;
        font-size: 32px;
        margin: 0;
    }

    h2 {
        font-family: "DM Sans", sans-serif;
        color: #3B374F;
        font-weight: 700 !important;
        font-size: 15px;
        margin: 0 0 5px 0;
    }

    h3 {
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 700 !important;
        font-size: 20px;
        margin: 0;
    }

    p {
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 600;
        font-size: 14px;
        margin: 8px auto;
    }

    a {
        color: #8D68FF;
    }

    .featureGrid p {
        max-width: 370px;
    }

    .sectionHeader {
        max-width: 650px;
        margin: 0 auto;
    }

    .buyButton {
        font-family: "DM Sans", sans-serif !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        display: inline-block;
        transition: 0.2s !important;
        -webkit-transition: 0.2s !important;
        z-index: 1;
        padding: 9px 20px !important;
        color: white;
        background: #6420FF;
        border-radius: 15px;
    }

    .buyButton:hover {
        color: #6420FF;
        background: white;
        transform: scale(1.05);
    }

    .featureIcon {
        margin-bottom: 10px;
    }

    .cloud1 {
        width: 50px;
        height: auto;

        position: absolute;
        right: 5%;
        top: -30px;
    }

    .cloud2 {
        width: 110px;
        height: auto;

        position: absolute;
        right: 5%;
        top: -50px;
    }

    .cloud3 {
        width: 80px;
        height: auto;

        position: absolute;
        left: 0;
        bottom: -80px;
        z-index: 2;
    }

    .star {
        position: absolute;
        right: 12%;
        bottom: 50px;
    }

    .shootingstar {
        position: absolute;
        left: 0;
        bottom: 0px;
    }

    .cloudHolder {
        position: relative;
        width: 100%;
    }

    .rocketHolder {
        position: relative;
        width: 100%;
        padding-top: 125px;
    }

    .rocket {
        position: absolute;
        transform: translateX(-50%);
        z-index: -99;
        bottom: 0;
        min-width: 635px;
    }

    @media (max-width: 1190px) {
        .rocket {
            min-width: 655px;
        }
    }

    @media (max-width: 931px) {
        .rocket {
            min-width: 690px;
        }
    }

    @media (max-width: 794px) {
        .rocket {
            min-width: 720px;
        }
    }

    @media (max-width: 768px) {
        .featureStyling {
            width: 80%;
            max-width: 1400px;
            margin: 0 auto;
            padding: 60px 0;
            text-align: center;
        }

        .featureGrid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 40px;
            margin: 50px auto 50px auto;
        }

        h1 {
            font-family: "DM Sans", sans-serif;
            color: white;
            font-weight: 700 !important;
            font-size: 24px;
            margin: 0;
            text-align: center;
        }

        .rocket,
        .shootingstar,
        .cloud1,
        .cloud2,
        .cloud3,
        .rocketHolder {
            display: none;
        }

        .cloud1 {
            width: 50px;
            height: auto;

            position: absolute;
            right: 17%;
            top: 10px;
        }

        .cloud2 {
            width: 110px;
            height: auto;

            position: absolute;
            right: 15%;
            top: 10px;
        }

        .star {
            position: absolute;
            right: 11%;
            bottom: 62px;
        }
    }
}