#landing {
    text-align: center;
    margin: 0 auto;
    padding-top: 120px;
    width: 80%;
    min-height: 100vh;
    overflow: hidden;

    h1 {
        font-family: 'Inter', sans-serif !important;
        color: white;
        font-weight: 700 !important;
        font-size: 38px;
        margin-top: 5px;
    }

    p {
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 600;
        font-size: 14px;
        margin-top: 10px;
    }

    .textGradient {
        background: -webkit-linear-gradient(#AC87FF, #8D68FF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .textContainer {
        max-width: 800px;
        margin: 0 auto;
    }

    .buyButton {
        font-family: "DM Sans", sans-serif !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        display: inline-block;
        transition: 0.2s !important;
        -webkit-transition: 0.2s !important;
        z-index: 1;
        padding: 9px 20px !important;
        color: white;
        background: linear-gradient(302.04deg, #9870FF 2.07%, #AC87FF 73.72%);
        border-radius: 15px;
    }

    .buyButton:hover {
        color: white;
        transform: scale(1.05);
    }

    .learnButton {
        font-family: "DM Sans", sans-serif !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        display: inline-block;
        transition: 0.2s !important;
        -webkit-transition: 0.2s !important;
        z-index: 1;
        padding: 9px 20px !important;
        color: #0F0C23;
        background: white;
        border-radius: 15px;
        margin-left: 10px;
    }

    .learnButton:hover {
        color: #0F0C23;
        transform: scale(1.05);
        color: #6420FF;
    }

    .imageContainer {
        margin-top: 40px;
        height: 420px;
    }

    .sneakerServer {
        width: 300px;
        height: auto;
    }

    .sneaker {
        width: 135px;
        height: auto;

        position: relative;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        bottom: 290px;
        padding-right: 11px;
    }

    .gradientBackground {
        position: absolute;
        top: -150px;
        width: 700px;
        height: 500px;
        border-radius: 1000px;

        background: rgba(141, 104, 255, 0.17);
        filter: blur(400px);
    }

    .logobg {
        // position: relative;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // z-index: -1;

        width: 400px;
    }

    .absHolder {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }

    .lines {
        position: absolute;
        top: 62%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -2;
        width: 100%;

        display: none;
    }

    .statGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 15px;

        max-width: 600px;
        margin: 10px auto 20px auto;

        h2 {
            font-family: 'Inter', sans-serif !important;
            color: white;
            font-weight: 700 !important;
            font-size: 20px;
            margin: 0;
        }

        p {
            margin: 3px 0 0 0;
        }

        div:nth-child(2) {
            border-right: 1px solid #544C6B;
            border-left: 1px solid #544C6B;
            padding: 0 15px;
        }
    }

    .pTop {
        position: relative;
        z-index: 2;
    }

    .pBottom {
        position: relative;
        z-index: 1;
    }

    @media (max-width: 768px) {
        height: unset;
        padding-bottom: 40px;
        width: 95%;

        h1 {
            font-family: 'Inter', sans-serif !important;
            color: white;
            font-weight: 700 !important;
            font-size: 24px;
            margin-top: 0;
        }

        /* .gradientBackground {
            position: absolute;
            top: 0;
            width: 300px;
            height: 500px;
            border-radius: 1000px;

            background: rgba(141, 104, 255, .25);
            filter: blur(400px);
        } */

        .gradientBackground {
            display: none;
        }

        .logobg {
            display: none;
        }

        .sneakerServer {
            width: 80%;
            height: auto;
            max-width: 270px;
        }

        .statGrid {
            max-width: unset;
            margin: 40px auto 0 auto;

            h2 {
                font-size: 14px;
            }

            p {
                margin: 3px 0 0 0;
                font-size: 12px;
            }
        }

        .sneaker {
            width: 35%;
            height: auto;
            max-width: 140px;

            position: relative;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;

            bottom: 270px;
            padding-right: 11px;
        }

        .imageContainer {
            margin-top: 40px;
            height: 320px;
        }
    }
}