#faq {
    width: 80%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 80px 0;
    overflow: hidden;

    h1 {
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 700 !important;
        font-size: 32px;
        margin: 0;
        text-align: center;
    }

    h2 {
        font-family: "DM Sans", sans-serif;
        color: #3B374F;
        font-weight: 700 !important;
        font-size: 15px;
        margin: 0 0 5px 0;
        text-align: center;
    }

    p {
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 600;
        font-size: 14px;
        margin: 8px 0 20px 0;
        text-align: center;
    }

    a {
        color: #8D68FF;
    }

    .faqHeading {
        margin-bottom: 40px;
    }

    .mTop {
        margin-top: 25px;
    }

    .accordion-item {
        background-color: transparent;
        border: none;
        color: #D4CCE8;

        font-weight: 500;
        font-size: 14px;

        background-color: #271E44;
        border-radius: 15px;

        //margin-bottom: 15px;
    }

    .accordion-button {
        background-color: #271E44;
        color: white;
        border-radius: 15px;
        padding: 20px;

        font-size: 14px;
        font-weight: 600;
    }

    .accordion-body {
        padding: 15px 20px 20px 20px;
    }

    .faqHeader {
        margin-bottom: 0;
    }

    .accordion {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    /* .faqHeader:after {
        background: url(../../Images/question.svg);
        width: 180px;
        height: 180px;
        position: absolute;
    } */

    .well {
        margin: 0 !important;
    }

    .accordion-button:not(.collapsed) {
        color: white;
        box-shadow: none;
        border: none;
        outline: none;

        background-color: #3F3464;
        border-radius: 15px 15px 0 0;
    }

    .accordion-button:active,
    .accordion-button:focus {
        box-shadow: none;
    }

    .accordion-button:after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }
}

@media (max-width: 768px) {
    #faq {
        width: 90%;
        margin: 0 auto;
        padding: 50px 0;

        .accordion-button:after {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        }

        .accordion-button:not(.collapsed)::after {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        }

        h1 {
            font-family: "DM Sans", sans-serif;
            color: white;
            font-weight: 700 !important;
            font-size: 24px;
            margin: 0;
            text-align: center;
        }
    }
}

@media (max-width: 515px) {
    #faq {
        .accordion-button:after {
            margin-left: auto;
        }

        .accordion-button:not(.collapsed)::after {
            margin-left: auto;
        }
    }
}