#pricing2 {
    background-color: #14102A;
    position: relative;
    z-index: 2;
    padding: 5px 0;

    .pricingContainer {
        width: 80%;
        max-width: 1400px;
        margin: 0 auto;
        padding: 100px 0;
    }

    h1 {
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 700 !important;
        font-size: 32px;
        margin: 0;
    }

    h2 {
        font-family: "DM Sans", sans-serif;
        color: #3B374F;
        font-weight: 700 !important;
        font-size: 15px;
        margin: 0 0 5px 0;
    }

    h3 {
        font-family: 'Inter', sans-serif !important;
        color: white;
        font-weight: 700 !important;
        font-size: 18px;
        margin: 0;
    }

    h4 {
        font-family: 'Inter', sans-serif !important;
        color: #8D68FF;
        font-weight: 700 !important;
        font-size: 16px;
        margin: 0 0 3px 0;
    }

    p {
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 600;
        font-size: 14px;
        margin: 5px 0;
    }

    li {
        list-style-type: none;
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 600;
        font-size: 14px;
        margin: 6px 0;
    }

    li:before {
        content: url(../../Images/check.svg);
        margin-right: 10px;
    }

    a {
        color: rgb(117, 110, 141);
    }

    .termsText {
        color: #3B374F;
    }

    /* .priceScroll {
        display: flex;
        flex-direction: row;
        overflow: auto;

        padding-bottom: 20px;
    } */

    .priceScroll {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 15px;

        padding-bottom: 20px;
    }

    .sectionHeader {
        margin-bottom: 20px;
    }

    .pricingCard {
        background-color: #271E44;
        border-radius: 20px;
        width: 100%;
        min-width: 250px;
        // min-width: 270px;
        // max-width: 270px;
    }

    .priceHeader {
        background-color: #362E57;
        padding: 30px 20px;
        border-radius: 20px 20px 0 0;
        background: linear-gradient(45deg, rgba(54, 46, 87, 1), rgba(54, 46, 87, .75)), url(../../Images/pricebg.png);
    }

    .priceBody {
        padding: 15px 20px;
    }

    .purchaseButton {
        font-family: "DM Sans", sans-serif !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        display: inline-block;
        transition: 0.2s !important;
        -webkit-transition: 0.2s !important;
        z-index: 1;
        padding: 9px 20px !important;
        color: white;
        background: #6420FF;
        border-radius: 12px;

        border-width: 0 !important;

        width: 90%;
        margin-bottom: 20px;
    }

    .purchaseButton:hover {
        color: #6420FF;
        background: white;
        cursor: pointer;
    }

    .soldOut {
        background-color: #3F3464;
    }

    .soldOut:hover {
        background-color: #3F3464;
        color: white;
        cursor: default;
    }

    .fadeSlider {
        background: linear-gradient(90deg, rgba(20, 16, 42, 1), rgba(20, 16, 42, 0));
        // background-color: red;
        width: 35px;
        height: 340px;
        position: absolute;
        z-index: 2;
    }

    .fadeSlider2 {
        background: linear-gradient(90deg, rgba(20, 16, 42, 0), rgba(20, 16, 42, 1));
        width: 35px;
        height: 340px;
        position: absolute;
        z-index: 2;
        right: 10%;
    }

    // Select

    select {
        font-family: "DM Sans", sans-serif !important;
        font-size: 14px;
        font-weight: 500;

        border-radius: 12px;
        background: transparent;
        border: 1px solid #DBC8FF;
        color: #DBC8FF;

        padding: 10px 25px 10px 12px;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        margin: 0;
        height: 41px;

        width: 100%;

        -webkit-appearance: none;
        appearance: none;
        -webkit-appearance: none;
    }

    select:hover {
        cursor: pointer;
    }

    select:focus {
        outline: none;
        border: 1px solid #DBC8FF;
    }

    .select-wrapper {
        position: relative;

        width: 90%;
        margin: 0 auto 10px auto;
    }

    .select-wrapper::after {
        content: url(../../Images/arrow.svg);
        top: 7px;
        right: 12px;
        position: absolute;
    }

    // Other
    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    // ScrollBar
    /* width */
    ::-webkit-scrollbar {
        height: 7px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #29204E;
        border-radius: 500px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #3F3564;
        border-radius: 500px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #3F3564;
    }

    @media (max-width: 1310px) {
        .priceScroll {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;

            padding-bottom: 20px;
        }
    }

    @media (max-width: 768px) {
        .pricingContainer {
            width: 90%;
            margin: 0 auto;
            padding: 50px 0;
        }

        .priceScroll {
            display: grid;
            grid-template-columns: 1fr;
            gap: 15px;

            padding-bottom: 20px;
        }

        h1 {
            font-family: "DM Sans", sans-serif;
            color: white;
            font-weight: 700 !important;
            font-size: 24px;
            margin: 0;
        }
    }
}