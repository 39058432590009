#footer {
    background-color: #14102A;

    .footerStyle {
        margin: 0 auto;
        width: 80%;

        display: grid;
        grid-template-columns: 1.2fr 1fr 1fr 1fr .8fr;
        column-gap: 15px;
        row-gap: 20px;
        padding: 40px 0;
    }

    a {
        text-decoration: none;
    }

    p {
        font-family: "DM Sans", sans-serif;
        font-size: 12px !important;
        color: #D4CCE8;
        margin: 8px 0;
    }

    .logo {
        width: 45px;
        height: auto;
    }

    .footHead {
        color: #8D68FF !important;
        font-weight: 700 !important;
    }

    .toTop {
        color: white;
        font-weight: 700 !important;
        text-align: right;
    }
}

@media (max-width: 768px) {
    #footer {
        .footerStyle {
            margin: 40px auto 0 auto;
            width: 90%;

            display: grid;
            grid-template-columns: 1fr;
            column-gap: 15px;
            row-gap: 20px;
            padding-bottom: 30px;
        }

        .alignRight {
            text-align: left;
        }

        .logo {
            width: 35px;
            height: auto;
        }

        .toTop {
            text-align: left;
        }
    }
}