#currentIspPlans {
    h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
    }

    .currentPlans {
        width: 100%;
        background: linear-gradient(91.64deg, #262639 3.44%, rgba(38, 38, 57, 0.46) 145.99%);
        border-radius: 6px;
        margin: 0 auto;
        margin-top: 10px;
        transition: .2s;
        outline: solid 1px transparent;

        padding: 15px;
    }

    .currentPlans:hover {
        outline: solid 1px white;
        cursor: pointer;
    }

    .selected {
        outline: solid 1px white;
    }

    .currentPlans-heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    .heading-text {
        padding-top: 7px;
    }

    .proxy-details {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-top: 7px;
    }

    .currentPlans-details {
        font-weight: 450;
        font-size: 12px;
        line-height: 10px;
        color: #D4CCE8;
    }

    .currentPlans-info {
        font-weight: 500;
        font-size: 12px;
        line-height: 0;
    }
}