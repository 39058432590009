@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0F0C23 !important;
  color: white !important;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: "DM Sans", sans-serif !important;
}

h1,
h2,
h3,
h4,
p {
  font-family: "DM Sans", sans-serif !important;
  margin: 0;
}

.cursor-pointer{
  cursor: pointer;
}
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .disableSafari {
      -webkit-transform: none !important;
      -webkit-transition: none !important;
      opacity: 1 !important;
    }
  }
}