#soon {
    text-align: center;
    margin: 120px auto 0 auto;
    width: 90%;

    h1 {
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 700;
        font-size: 32px;
        margin-top: 5px;
    }

    p {
        font-family: "DM Sans", sans-serif;
        color: white;
        font-weight: 600;
        font-size: 14px;
        margin-top: 5px;
    }

    a {
        color: #8D68FF;
    }
}